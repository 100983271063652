import React from 'react'

import { Link } from 'gatsby'

import styles from './style.module.scss'

import Image from 'gatsby-image'

import { FaMapMarkerAlt } from 'react-icons/fa'

import DefaultThumbnail from '../single-project/default-thumbnail'

function getName(data, ids) {
    if (!data) {
        return ''
    }
    let filtered = data.edges.filter(
        edge => ids.indexOf(edge.node.wordpress_id) > -1
    )
    if (filtered.length) {
        return filtered[0].node.name
    }
    return ''
}
export const Project = ({ edge, data }) => (
    <Link to={'/project/' + edge.node.slug} className={styles.project}>
        {edge.node.featured_media &&
            edge.node.featured_media.localFile &&
            edge.node.featured_media.localFile.childImageSharp &&
            edge.node.featured_media.localFile.childImageSharp.fluid ? (
                <Image
                    alt={
                        edge.node.featured_media &&
                        edge.node.featured_media.alt_text
                    }
                    fluid={
                        edge.node.featured_media &&
                        edge.node.featured_media.localFile &&
                        edge.node.featured_media.localFile.childImageSharp &&
                        edge.node.featured_media.localFile.childImageSharp.fluid
                    }
                    className={styles.image}
                />
            ) : (
                <DefaultThumbnail className={styles.image} />
            )}

        <span
            className={styles.developer}
            dangerouslySetInnerHTML={{
                __html: getName(
                    data.allWordpressWpDevelopers,
                    edge.node.developers
                )
            }}
        />
        <h3
            dangerouslySetInnerHTML={{
                __html: edge.node.title
            }}
        />
        <span className={styles.area}>
            <FaMapMarkerAlt />
            {getName(data.allWordpressWpAreas, edge.node.areas)}
        </span>
        <div className={styles.grades}>
            <div className={styles.grade}>
                Investor grade
                <span>{edge.node.acf.investor_grade}</span>
            </div>
            <div className={styles.grade}>
                End user grade
                <span>{edge.node.acf.end_user_grade}</span>
            </div>
        </div>
    </Link>
)
export default ({ data }) => {
    return (
        <div className={styles.projects}>
            <div className="wrapper">
                <div className="row">
                    {data &&
                        data.allWordpressWpProject &&
                        data.allWordpressWpProject.edges.map(edge => {
                            return (
                                <div
                                    key={edge.node.slug}
                                    className="col-xs-12 col-sm-6 col-md-4"
                                >
                                    <Project edge={edge} data={data} />
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
