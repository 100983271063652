import React from 'react'

import styles from './style.module.scss'

import { Link } from 'gatsby'

import cn from 'classnames'

export default ({ className, children, label, onClick }) => (
    <div
        className={cn(styles.card, className, {
            [styles.clickable]: !!onClick
        })}
        onClick={e => onClick && onClick(e)}
    >
        {children}
        {label && <h3>{label}</h3>}
    </div>
)

export const DataCard = ({ label, icon, children, h1 }) => (
    <div className={styles.dataCard}>
        {h1 ? (
            <h1>
                {icon} {label}
            </h1>
        ) : (
            <h3>
                {icon} {label}
            </h3>
        )}

        <div className={styles.body}>{children}</div>
    </div>
)

export const LinkCard = ({
    to,
    className,
    titleClassName,
    label,
    children
}) => (
    <Link className={cn(styles.card, className, styles.linkCard)} to={to}>
        {children}
        {label && <h3 className={titleClassName}>{label}</h3>}
    </Link>
)
