import React from 'react'

import { Link } from 'gatsby'

import styles from './style.module.scss'

import cn from 'classnames'

function getItemsOfCategory(data, categoryId) {
    if (!data.allWordpressWpSeoSearch) return []
    return data.allWordpressWpSeoSearch.edges
        .filter(edge => {
            return (
                edge.node.seo_search_category &&
                edge.node.seo_search_category.indexOf(categoryId) > -1
            )
        })
        .slice(0, 6)
}

function getAreaSlug(data, edge) {
    if (!data.allWordpressWpAreas || !edge) return []

    let area = data.allWordpressWpAreas.edges.filter(areaEdge => {
        return (
            edge.node.areas &&
            edge.node.areas.indexOf(areaEdge.node.wordpress_id) > -1
        )
    })[0]

    return area && area.node.slug
}
export default ({ data }) => {
    return data.allWordpressWpSeoSearchCategory ? (
        <div className={cn(styles.seoSearchContainer, styles.searches)}>
            <div className="wrapper">
                <div className="row">
                    {data.allWordpressWpSeoSearchCategory.edges.map(edge => (
                        <div
                            className="col-xs-12 col-sm-6"
                            key={edge.node.wordpress_id}
                        >
                            <h3>{edge.node.name}</h3>
                            <ul>
                                {getItemsOfCategory(
                                    data,
                                    edge.node.wordpress_id
                                ).map((edge, i) => (
                                    <li key={i}>
                                        <Link
                                            to={
                                                '/area/' +
                                                getAreaSlug(data, edge)
                                            }
                                            key={edge.node.slug}
                                            className={styles.seoSearchLink}
                                            title={edge.node.title}
                                        >
                                            {edge.node.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null
}
